import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from 'config';
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState: any = {
  courses: []
};

const courses = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourses(state, action) {
      state.courses = action.payload;
    },
    reset: () => initialState
  }
});

export default courses.reducer;

export function getCourses() {
  return async () => {
    try {
      const response = await axios.get(`${BASE_URL}/course/admin/all`);
      dispatch(courses.actions.setCourses(response.data.courses) || []);
    } catch (error) {
      console.log(error);
    }
  };
}
